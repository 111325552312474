import React from "react";

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
} from "@react-pdf/renderer";
import logo from './../../Components/common/imagenes/img-bc-pdf.png'

const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 18;
const COLN_WIDTH = (100 - COL1_WIDTH) / 3;
const styles = StyleSheet.create({
    body: {
        padding: 10,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableCol1Header: {
        width: COL1_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColHeader: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol1: {
        width: COL1_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 12,
        fontWeight: 500,
    },
    tableCell: {
        margin: 5,
        fontSize: 10,
        textAlign: "center",
    },
    imageContainer: {
        alignSelf: 'flex-end', // Alinea la imagen a la derecha
        marginRight: 10, // Espacio entre la imagen y el borde derecho
        marginTop: 10, // Espacio superior para separar la imagen de otros elementos
    },
    image: {
        alignSelf: 'flex-end', // Alinea la imagen hacia la derecha
        width: 200,
        height: 100,
        marginBottom: 10,
    },
    header: {
        height: 150,
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },
    fecha: {
        marginLeft: "257",
        fontSize: 13,
    },
    headerInfo: {
        width: "70%",
        float: "letf",
        margin: "2%",
        fontSize: 10,
        flex: 4,
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
    },
    headerImg: {
        flexDirection: "row",
        flexWrap: "wrap",

        marginRight: "20px",

        float: "rigth",
        width: 200,

        margin: "1%",
    },

    numeroPre: {
        width: "320px",
        padding: "2px",
        border: "2px solid",
        margin: 0,
        fontSize: 12,
        fontWeight: "bold",
    },
    frase: {
        fontSize: 10,
        textAlign: "left !important",
        padding: "10px",
        fontWeight: "bold",
        width: "30px",
    },
});

let today = new Date(),
    date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();

const PDF = (props) => {
    const { pedidos, totalPedido, cliente } = props;
    

    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <View style={styles.header}>
                    <View style={styles.headerInfo}>
                        <Text style={styles.headerInfo}>
                            {"\n"}
                            <Text style={styles.headerInfo}>
                                distribuidoraBC@gmail.com
                            </Text>{" "}
                            {"\n"}
                            <Text style={styles.headerInfo}>
                                www.pedidosBC.com.ar
                            </Text>
                            {"\n"}
                            <Text style={styles.headerInfo}>
                                Calle 122 #1006
                            </Text>{" "}
                            {"\n"}
                            <Text style={styles.headerInfo}>
                                La Plata - Buenos Aires
                            </Text>{" "}
                            {"\n"}
                            <Text style={styles.headerInfo}>
                                +54 9 221 - 2213641029
                            </Text>{" "}
                            {"\n"}
                        </Text>

                        <View style={styles.headerInfo}>
                            
                            <Image src={logo} style={[styles.image, styles.imageContainer]}/>
                            <Text style={styles.fecha}>{date}</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.numeroPre}>
                    <Text>Nº:</Text>
                    <Text>Sr:{pedidos.cliente}</Text>
                </View>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>
                                Producto
                            </Text>
                        </View>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>Cantidad</Text>
                        </View>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>Cambio</Text>
                        </View>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>Precio</Text>
                        </View>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>SubTotal</Text>
                        </View>
                    </View>
                    {pedidos
                        ? 
                               Object.values(pedidos.productos).map((b, index) => (
                                (b.cantidad > 0 || b.cambio != 0) &&
                                  <View key={index} style={styles.tableRow}>
                                      <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>
                                              {b.marca+" "+b.productoNombre}
                                          </Text>
                                      </View>
                                      <View style={styles.tableCol1}>
                                          <Text style={styles.tableCell}>
                                              {b.cantidad}
                                          </Text>
                                      </View>
                                      <View style={styles.tableCol1}>
                                          <Text style={styles.tableCell}>
                                              {b.cambio}
                                          </Text>
                                      </View>
                                      <View style={styles.tableCol1}>
                                          <Text style={styles.tableCell}>
                                              {b.precio}
                                          </Text>
                                      </View>
                                      <View style={styles.tableCol1}>
                                          <Text style={styles.tableCell}>
                                              {(b.precio * b.cantidad) + (b.precio * b.cambio)}
                                          </Text>
                                      </View>
                                  </View>
                              ))
                         
                        : ""}
                    
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Total:</Text>
                        </View>
                        <View style={styles.tableCol1}>
                            <Text style={styles.tableCell}>{}</Text>
                        </View>
                        <View style={styles.tableCol1}>
                            <Text style={styles.tableCell}>{}</Text>
                        </View>
                        <View style={styles.tableCol1}>
                            <Text style={styles.tableCell}>{}</Text>
                        </View>
                        <View style={styles.tableCol1}>
                            <Text style={styles.tableCell}>${pedidos.total}</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <Text style={styles.frase}>Nota:</Text>
                    <Text tyle={styles.linea}> </Text>
                </View>
                
                <View style={styles.numeroPre}>
                    <Text>Nº:</Text>
                    <Text>Sr:{pedidos.cliente}</Text>
                </View>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>
                                Producto
                            </Text>
                        </View>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>Cantidad</Text>
                        </View>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>Cambio</Text>
                        </View>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>Precio</Text>
                        </View>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>SubTotal</Text>
                        </View>
                    </View>
                    {pedidos
                        ? 
                              
                              Object.values(pedidos.productos).map((b, index) => (
                                 (b.cantidad > 0 || b.cambio != 0) &&
                                  <View key={index} style={styles.tableRow}>
                                      <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>
                                          {b.marca+" "+b.productoNombre}
                                          </Text>
                                      </View>
                                      <View style={styles.tableCol1}>
                                          <Text style={styles.tableCell}>
                                              {b.cantidad}
                                          </Text>
                                      </View>
                                      <View style={styles.tableCol1}>
                                          <Text style={styles.tableCell}>
                                              {b.cambio}
                                          </Text>
                                      </View>
                                      <View style={styles.tableCol1}>
                                          <Text style={styles.tableCell}>
                                              {b.precio}
                                          </Text>
                                      </View>
                                      <View style={styles.tableCol1}>
                                          <Text style={styles.tableCell}>
                                              {(b.precio * b.cantidad) + (b.precio * b.cambio)}
                                          </Text>
                                      </View>
                                  </View>
                              ))
                          
                        : ""}
                    

                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Total:</Text>
                        </View>
                        <View style={styles.tableCol1}>
                            <Text style={styles.tableCell}>{}</Text>
                        </View>
                        <View style={styles.tableCol1}>
                            <Text style={styles.tableCell}>{}</Text>
                        </View>
                        <View style={styles.tableCol1}>
                            <Text style={styles.tableCell}>{}</Text>
                        </View>
                        <View style={styles.tableCol1}>
                            <Text style={styles.tableCell}>${pedidos.total}</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <Text style={styles.frase}>Nota:</Text>{" "}
                    <Text tyle={styles.linea}> </Text>
                </View>
            </Page>
        </Document>
    );
};
export default PDF;
