import React, { useState, useEffect } from "react";
import { Modal, Button, Table } from "rsuite";
import { useSelector, useDispatch } from "react-redux";
import {PDFDownloadLink} from '@react-pdf/renderer'
import PDF from './Pdf'
const { Column, HeaderCell, Cell } = Table;


const ModalProductos = (props) => {
    const productos = props.productos;
    const change = props.changueProducto;
    console.log(productos,"laslallsla")

    const dispatch = useDispatch();

    const [show, setShow] = useState(productos != null);
    
    useEffect(() => {
      setShow(productos != null)
    }, [productos])

    const handleClose = (e) => {
            setShow(false);
    };
    
    const handleCloseButton = () => {
        setShow(false);
        change(null)
    };

    const handleShow = () => setShow(true);

    return (
        <>
            {show && (
                <Modal open={show} onClose={handleClose}>
                    <Modal.Header>
                        <Modal.Title>Productos:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {productos.productos.length > 0 ?
                        <Table responsive hover
                            height={400}
                            data={productos.productos}
                        >
                            <Column width={50} align="center" fixed>
                                <HeaderCell>Id</HeaderCell>
                                <Cell dataKey="idProducto" />
                            </Column>

                            <Column width={100}>
                                <HeaderCell>Marca</HeaderCell>
                                <Cell dataKey="marca"  />
                            </Column>


                            <Column width={200}>
                                <HeaderCell>Producto</HeaderCell>
                                <Cell  dataKey="productoNombre" />
                            </Column>

                            <Column width={150}>
                                <HeaderCell>Cantidad</HeaderCell>
                                <Cell dataKey="cantidad" />
                            </Column>

                            <Column width={100}>
                                <HeaderCell>Cambio</HeaderCell>
                                <Cell dataKey="cambio" />
                            </Column>

                            
                            <Column width={100}>
                                <HeaderCell>SubTotal</HeaderCell>
                                <Cell dataKey="subTotal" />
                            </Column>
                  
                        </Table> : "Pedido sin productos"}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            appearance="secondary"
                            onClick={handleCloseButton}
                        >
                            Cerrar
                        </Button>
                        <Button>
                                    <PDFDownloadLink document={<PDF pedidos={ productos } />} fileName="archivo.pdf">
                                     {({ blob, url, loading, error }) => (
                                            loading ? (
                                                <Button variant="danger" >descargando Pdf</Button>
                                            ) : (
                                                <Button variant="danger" >descargar PDF</Button>
                                            )
                                        )}
                                    </PDFDownloadLink>
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default ModalProductos;
