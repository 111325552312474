import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Table, Button } from "react-bootstrap";
//import Modal from './ModalEditarCliente';
import { eliminarProducto } from "../../actions/producto";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
    setAllCheckTablaPedido,
    setCheckTablaPedido,
} from "../../actions/tablaPedido";
import { AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import  ModalProductos  from "./ModalProductos";

const TablaPedido = (props) => {
    const [producto, setProducto] = useState(null);
    
    const [allChecks, setAllChecks] = useState(false);
    const checks = useSelector((state) => state.pedidoCheck);

    const dispatch = useDispatch();

    const filtro = props.filter;
    const { pagina = null } = props;

    const onOff = (e) => {
        const data = {
            codigo: e,
        };
        dispatch(eliminarProducto(data));
    };

    let position = 0
        for (let i = 0; i < checks.pedidos.length; i++) {
            if (checks.pedidos[i].pedido.id === props.clientes[0].id) {
                position = i;
                break;
            }
        }

    useEffect(() => {
        if(!checks.pedidos.length){
            props.clientes?.forEach((producto) => {
                const data = {
                    pedido: producto,
                    stado: false,
                };
                dispatch(setCheckTablaPedido(data));
            });
        }
    }, []);

    useEffect(() => {
        let clienteIncluido = false;

        for (let i = 0; i < checks.pedidos.length; i++) {
            if (checks.pedidos[i].pedido.id === props.clientes[0].id) {
                clienteIncluido = true;
                break;
            }
        }

        if(!clienteIncluido && props.clientes ){
            props.clientes?.forEach((producto) => {
                const data = {
                    pedido: producto,
                    stado: false,
                };
                dispatch(setCheckTablaPedido(data));
            });
        }
        
    }, [props.clientes]);

  
    const handleChange = (event, index, pedido) => { 
        const data = {
            pedido: pedido,
            stado: event.target.checked,
        };
        dispatch(setCheckTablaPedido(data));
    };

    const handleChange1 = (event) => {
        
        const updatedPedidos = checks?.pedidos.map((element, index) => {
            if (index >= position && index < position + 10) {
                return { ...element, stado: event.target.checked };
            } else {
                return element;
            }
        });
        
        //checks?.pedidos.map((element)=>({...element , stado:event.target.checked}))
        dispatch(setAllCheckTablaPedido(updatedPedidos));
    };

    const listItems = props.clientes?.map((producto, index) => (
        <tr key={producto.id}>
            <th scope="row">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checks?.pedidos.some((data) => {
                                let result = false;
                                result =
                                    data.pedido.id === producto.id &&
                                    data.stado == true;
                                return result;
                            })}
                            onChange={(e) => handleChange(e, index, producto)}
                            value={producto.id}
                        />
                    }
                />
                {index + 1}
            </th>
            <td>{producto.cliente}</td>
            <td>{producto.fecha}</td>
            <td>$ {producto.total}</td>
            <td>
                <Button
                    style={{fontSize: "23px"}}
                    variant="black"
                    type="button"
                    onClick={() => setProducto( producto )
                    }
                >
                   <AiOutlineEdit></AiOutlineEdit>
                </Button>
            </td>
            {/* <td>
                <Button
                    style={{fontSize: "23px"}}
                    variant="black"
                    onClick={() => {
                        onOff(producto.id);
                    }}
                >
                    <AiOutlineClose></AiOutlineClose>
                </Button>
            </td> */}
        </tr>
    ));

    return (
        <div className="table">
            <Card>
                <Card.Header style={{display: "flex", flexDirection: "column"}}>
                
                </Card.Header>
                <Card.Body>
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th><FormControlLabel 
                        control={
                            <Checkbox
                                checked={//checks.pedidos.every((value) => value.stado)
                                    checks.pedidos.slice(position, position + 10).every((value) => value.stado)
                                }
                                indeterminate={
                                    checks.pedidos.some((value) => value.stado) &&
                                    checks.pedidos.some((value) => !value.stado)
                                }
                                onChange={handleChange1}
                            />
                        }
                    />#</th>
                                <th>Cliente</th>
                                <th>Fecha</th>
                                <th>Total</th>
                                <th>Productos</th>
                            </tr>
                        </thead>
                        <tbody>{listItems}</tbody>
                    </Table>
                    <ModalProductos  productos= {producto} changueProducto={setProducto}/>
                </Card.Body>
            </Card>
        </div>
    );
};
export default TablaPedido;
